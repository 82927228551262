<template>
    <div class="change-team-container">
        <van-cell-group inset :border="false" title="当前团队">
            <van-cell title="所属工作室" center :value="team.orgName" />
            <van-cell title="主咨询师" center :value="getName(team.master)" />
            <van-cell title="团队成员" center :value="getName(team.consultant)" />
        </van-cell-group>

        <van-cell-group inset :border="false" title="团队调整" class="change-team">
            <van-cell
                title="所属工作室"
                required
                center
                :value="newTeam.orgName"
                is-link
                @click="showSelector('org')"
            />

            <van-cell
                title="主咨询师"
                required
                center
                :value="getName(newTeam.master)"
                is-link
                @click="showSelector('master')"
            />

            <van-cell
                title="团队成员"
                required
                center
                :value="getName(newTeam.consultant)"
                is-link
                @click="showSelector('consultant')"
            />
        </van-cell-group>

        <div class="btn-group">
            <van-button color="var(--secondMainColor)" @click="goBack"> 返回 </van-button>
            <van-button color="var(--mainColor)" :loading="submitting" @click="onSubmit">
                提交
            </van-button>
        </div>

        <!-- 选择工作室 -->
        <select-user
            :show="showOrgSelector"
            :isRadio="true"
            title="选择工作室"
            isSelectedWorkRoom
            formData="?showPeople=false"
            :selectedUser="[newTeam.orgId]"
            @close="showOrgSelector = false"
            @confirm="onConfirm"
        />

        <!-- 选择人员 -->
        <select-user
            :show="showUserSelector"
            v-bind="selectorOption"
            @close="showUserSelector = false"
            @confirm="onConfirm"
        />
    </div>
</template>

<script>
import { queryGroup, saveAdjustGroup } from "@/api/hoss/team";
import SelectUser from "@/components/SelectUser";
export default {
    components: { SelectUser },
    data() {
        return {
            teamList: [],
            team: { master: [], consultant: [], orgId: "", orgName: "" },
            newTeam: { master: [], consultant: [], orgId: "", orgName: "" },

            // 按钮加载状态
            submitting: false,

            // 级联选择器参数
            showOrgSelector: false,
            showUserSelector: false,
            selectorOption: null,
        };
    },
    async created() {
        const { clientId } = this.$route.query;
        this.clientId = clientId;
        await this.queryGroupMember();
    },
    methods: {
        // 查看群组成员
        async queryGroupMember() {
            const { value } = await queryGroup(this.clientId);
            const { consultantList, master, orgId, orgName, serviceId } = value;
            const data = {
                consultant: consultantList.map((item) => ({
                    id: item.memberId,
                    name: item.name,
                })),
                master: master.map((item) => ({
                    id: item.memberId,
                    name: item.name,
                })),
                orgId,
                orgName,
                serviceId,
            };
            this.team = data;
            this.newTeam = JSON.parse(JSON.stringify(data));
        },

        async onSubmit() {
            const { consultant, master, orgId, orgName, serviceId } = this.newTeam;
            if (!consultant.length || !master.length || !orgId) {
                return this.$toast("请完善团队信息");
            }
            this.submitting = true;
            const data = {
                consultantList: consultant.map((item) => ({
                    memberId: item.id,
                    name: item.name,
                })),
                master: master.map((item) => ({
                    memberId: item.id,
                    name: item.name,
                })),
                orgId,
                orgName,
                serviceId,
                clientId: this.clientId,
            };
            try {
                await saveAdjustGroup(data);
                this.goBack();
                this.submitting = false;
            } catch (error) {
                this.submitting = false;
            }
        },

        goBack() {
            this.$router.back(-1);
        },

        onConfirm(val) {
            if (this.selectorType == "org") {
                const [org] = val;
                this.newTeam.orgId = org.id;
                this.newTeam.orgName = org.label;
            } else if (this.selectorType == "master") {
                this.newTeam.master = val;
            } else {
                this.newTeam.consultant = val;
            }
        },

        // 打开选择器
        showSelector(type) {
            this.selectorType = type;
            if (type == "org") {
                return (this.showOrgSelector = true);
            } else if (type == "master") {
                this.selectorOption = {
                    title: "选择主咨询师",
                    isRadio: true,
                    selectedUser: this.newTeam.master.map((item) => item.id),
                    disabledUser: this.newTeam.consultant.map((item) => item.id),
                };
            } else {
                this.selectorOption = {
                    title: "选择团队成员",
                    isRadio: false,
                    selectedUser: this.newTeam.consultant.map((item) => item.id),
                    disabledUser: this.newTeam.master.map((item) => item.id),
                };
            }
            this.showUserSelector = true;
        },
        getName(arr) {
            return arr.map((item) => item.name || item.label).join("、");
        },
    },
};
</script>

<style scoped lang="less">
.change-team-container {
    padding-bottom: 54px;
}
.van-cell-group {
    margin: 0 10px;
    border-radius: 10px;
    .van-cell__title {
        flex: 0 0 25%;
    }
    &.change-team .van-cell__value {
        color: var(--titleColor);
    }
}
/deep/.van-cell-group__title {
    color: var(--mainColor);
    padding-left: 24px;
}
.btn-group {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    .van-button {
        flex: 1 0 50%;
        border-radius: 0;
    }
}
</style>
