import request from "../request";

// 创建团队
export function newTeam(data) {
    return request({
        method: "json",
        url: `/client/group/save`,
        data,
    });
}

// 查询团队成员(调整团队时查询)
export function queryGroup(clientId) {
    return request({
        method: "get",
        url: `/client/group/current?clientId=${clientId}`,
    });
}

// 查询团队成员(调整团队时查询)
export function saveAdjustGroup(data) {
    return request({
        method: "json",
        url: `/client/group/save`,
        data,
    });
}
